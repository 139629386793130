// ConnectButton.tsx
import { Button } from '@chakra-ui/react';
import { useEthers } from '@usedapp/core';

type Props = {
  setQuery: (query: string) => void;
};

export default function ConnectButton(props: Props) {
  let setQuery = props.setQuery;

  const { activateBrowserWallet, deactivate, account } = useEthers();

  function handleConnectWallet() {
    setQuery('');
    activateBrowserWallet();
  }

  function handleDeactivateAccount() {
    setQuery('');
    deactivate();
  }

  return account ? (
    <Button onClick={handleDeactivateAccount} w="200px">
      Disconnect
    </Button>
  ) : (
    <Button onClick={handleConnectWallet} w="200px">
      Connect wallet
    </Button>
  );
}
