// index.tsx
import React from 'react';
// import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import App from './App';

// Import DAppProvider
import { DAppProvider } from '@usedapp/core';

// add as HTMLElement due to typescript requirements
const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <DAppProvider config={{}}>
      <App />
    </DAppProvider>
  </React.StrictMode>
);
