// ads.ts

// import { nansenAd } from '../components/Ad';

export const defaultAd =
  '<iframe title="placeholder" height="90px" width="728px" srcdoc="Connect or search for a wallet to see ads"/>';

export async function getAdForAccount(account: string): Promise<string> {
  if (!account?.length) {
    return defaultAd;
  }

  try {
    const apiURL = process.env.REACT_APP_API_URL;
    if (!apiURL) {
      throw new Error('error getting api URL');
    }
    const response = await fetch(`${apiURL}/ad/` + account);
    console.log(response);
    if (response?.ok) {
      /**
       * leaving this here so you can test without relying on backend
       *  */
      // return nansenAd;

      return response.text();
    } else if (!response.ok && response.status == 400) {
      console.log('bad wallet');
      return `<iframe title="placeholder" height="90px" width="728px" srcdoc="Wallet does not meet ad targeting criteria."/>`;
    }

    return defaultAd;
  } catch (err) {
    console.error(err);
    return defaultAd;
  }
}
