// AdArea.tsx
import { Box, Text, Center } from '@chakra-ui/react';
import { useEthers } from '@usedapp/core';
import { useEffect, useState } from 'react';
import { defaultAd, getAdForAccount } from '../hooks/ads';
import '../Ad.css';

interface Props {
  query: string;
}

export default function AdArea(props: Props) {
  const { account } = useEthers();
  const [ad, setAd] = useState(defaultAd);

  const [currentAccount, setCurrentAccount] = useState('');

  // const [iFrameAd, setiFrameAd] = useState(defaultAd);
  // const blob = new Blob([iFrameAd], { type: "text/html" });
  // const objectUrl = URL.createObjectURL(blob);
  // setiFrameAd(objectUrl);

  useEffect(() => {
    const getAd = async () => {
      setCurrentAccount(account ?? '');
      const adResponse = await getAdForAccount(account ?? '');
      setAd(adResponse);
    };
    getAd();
  }, [account]);

  useEffect(() => {
    const getAd = async () => {
      setCurrentAccount(props.query ?? '');
      const adResponse = await getAdForAccount(props.query ?? '');
      setAd(adResponse);
    };
    getAd();
  }, [props.query]);

  return currentAccount ? (
    //   Maybe add logic here? If ad criteria is met, show ad, otherwise show error message
    //   <Center bg="gray.50" rounded={'md'} p={2} h="90px" color="grey">
    //   Wallet did not meet ad targeting criteria.
    // </Center>

    <Box>
      <Text pb={2}>
        {currentAccount &&
          `${currentAccount.slice(0, 6)}...${currentAccount.slice(
            currentAccount.length - 4,
            currentAccount.length
          )}`}{' '}
      </Text>
      <Box bg="gray.50" rounded={'md'} p={2}>
        <div dangerouslySetInnerHTML={{ __html: ad }} />
      </Box>
    </Box>
  ) : (
    <Box>
      <Center bg="gray.50" rounded={'md'} p={2} h="90px" color="grey">
        No wallet connected or searched for.
      </Center>
    </Box>
  );
}
