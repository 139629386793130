// BannerAd.tsx
import React, { Component } from 'react';
import { ChakraProvider, Text } from '@chakra-ui/react';
import theme from '@chakra-ui/theme';
import Layout from './components/Layout';
import ConnectButton from './components/ConnectButton';
import AdArea from './components/AdArea';
import ActionBar from './components/ActionBar';
import SearchInput from './components/SearchInput';
import Intro from './components/Intro';
import { useState } from 'react';

function App() {
  const [query, setQuery] = useState('');

  return (
    <ChakraProvider theme={theme}>
      <Layout>
        <Intro />
        <ActionBar>
          <ConnectButton setQuery={setQuery} />
          <Text> or </Text>
          {/* <Spacer /> */}
          <SearchInput query={query} setQuery={setQuery} />
        </ActionBar>
        <AdArea query={query} />
      </Layout>
    </ChakraProvider>
  );
}

export default App;
