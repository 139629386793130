// SearchInput.tsx
import {
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

type Props = {
  query: string;
  setQuery: (query: string) => void;
};

export default function SearchInput(props: Props) {
  let { setQuery } = props;
  const [inputValue, setInputValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleClick = () => {
    console.log('SearchInput.tsx, InputValue: ' + inputValue);
    setQuery(inputValue);
  };

  useEffect(() => {
    setInputValue(props.query);
  }, [props.query]);

  return (
    <InputGroup size="md">
      <Input
        pr="4.5rem"
        placeholder="Enter in a wallet address (ENS not supported)"
        value={inputValue}
        onChange={handleChange}
      />
      <InputRightElement width="5.75rem">
        <Button h="1.75rem" size="md" onClick={handleClick}>
          <Text>Submit</Text>
        </Button>
      </InputRightElement>
    </InputGroup>
  );
}
