import React from 'react';
import { Box, Text, List, ListItem } from '@chakra-ui/react';

const AdTargetingAndPersonalizationDemo: React.FC = () => {
  return (
    <Box>
      <Text fontSize="xl" fontWeight="bold">
        Ad Targeting and Personalization Demo
      </Text>
      <br />
      <List styleType="disc" pl={6}>
        <ListItem>
          This demo shows how we can target an ad for a given wallet address, as
          well as personalize the data shown in the ad itself.
        </ListItem>
        <ListItem>
          An ad is returned if the wallet address is deemed an NFT trader,
          defined as having bought or sold 1+ NFT in the past month.
        </ListItem>
        <ListItem>
          The personalized ad shows their ENS to catch their attention, and
          contrasts their high trading volume with the subscription price to
          make the price feel like a bargain.
        </ListItem>
        <ListItem>
          For an example, try out Zeneca's address:
          0x886478d3cf9581b624cb35b5446693fc8a58b787 and Vitalik's address:
          0xd8da6bf26964af9d7eed9e03e53415d37aa96045
        </ListItem>
      </List>
      <br />
    </Box>
  );
};

export default AdTargetingAndPersonalizationDemo;
