import { ReactNode } from 'react';
import { Flex, HStack } from '@chakra-ui/react';

type Props = {
  children?: ReactNode;
};

export default function ActionBar({ children }: Props) {
  return (
    <HStack
      spacing="12px"
      direction="row"
      align="center"
      justify="start"
      h="48px"
    >
      {children}
    </HStack>
  );
}
