import { ReactNode } from 'react';
import { Flex, Stack, useColorModeValue } from '@chakra-ui/react';

interface Props {
  children?: ReactNode;
}

export default function Layout({ children }: Props) {
  return (
    <Flex
      flexDirection="column"
      align="center"
      justify="center"
      h="100vh"
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack
        spacing={4}
        // w={'full'}
        // maxW={'1018px'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}
        maxW="792px"
      >
        {children}
      </Stack>
    </Flex>
  );
}
